
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
// import { ElMessage, ElNotification } from "element-plus";
import {
  getShowStatus,
  getJWHandleNum,
  getPushInfo,
} from "../config/interface";
// import {
//   getShowStatus,
//   getJWHandleNum,
//   getPushInfo,
//   handleInfo,
// } from "../config/interface";
import '../assets/iconFont/iconfont.css'
export default {
  setup() {
    onMounted(() => {
      getShowStatus({}).then((res) => {
        if (res.data.data && res.data.success) {
          data.statusNum = res.data.data;
        } else {
          ElMessage.error(res.data.msg);
        }
      });
      getJWList("", "");
      getPushInfo({}).then((res) => {
        if (res.data.data && res.data.success) {
          // 清空数据
          data.titleOne = "";
          data.titleTwo = "";
          data.titleThree = "";
          data.titleFour = "";
          data.titleFive = "";
          data.titleSix = "";
          res.data.data.map((item: any) => {
            if (item.type == 1) {
              data.titleOne = data.titleOne + item.sname + '、';
            } else if (item.type == 2) {
              data.titleTwo = data.titleTwo + item.sname + '、';
            } else if (item.type == 3) {
              data.titleThree = data.titleThree + item.sname + '、';
            } else if (item.type == 4) {
              data.titleFour = data.titleFour + item.sname + '、';
            } else if (item.type == 5) {
              data.titleFive = data.titleFive + item.sname + '、';
            } else if (item.type == 6) {
              data.titleSix = data.titleSix + item.sname + '、';
            }
          })
          // data.noticeArr = res.data.data;
          // data.noticeArr.map((item: any) => {
          //   setTimeout(() => {
          //     if (item.type === 1) {
          //       ElNotification({
          //         title: "订单未匹配",
          //         message: item.content,
          //         type: "warning",
          //         duration: 0,
          //         onClose: () => {
          //           handleInfo({ infoId: item.id }).then((res) => {
          //             if (res.data && res.data.success) {
          //               ElMessage.success(res.data.msg);
          //             } else {
          //               ElMessage.error(res.data.msg);
          //             }
          //           });
          //         },
          //       });
          //     } else if (item.type === 2) {
          //       ElNotification({
          //         title: "未上课",
          //         message: item.content,
          //         type: "error",
          //         duration: 0,
          //         onClose: () => {
          //           handleInfo({ infoId: item.id }).then((res) => {
          //             if (res.data && res.data.success) {
          //               ElMessage.success(res.data.msg);
          //             } else {
          //               ElMessage.error(res.data.msg);
          //             }
          //           });
          //         },
          //       });
          //     } else if (item.type === 3) {
          //       ElNotification({
          //         title: "研究生未评价",
          //         message: item.content,
          //         type: "warning",
          //         duration: 0,
          //         onClose: () => {
          //           handleInfo({ infoId: item.id }).then((res) => {
          //             if (res.data && res.data.success) {
          //               ElMessage.success(res.data.msg);
          //             } else {
          //               ElMessage.error(res.data.msg);
          //             }
          //           });
          //         },
          //       });
          //     } else if (item.type === 4) {
          //       ElNotification({
          //         title: "学生未评价",
          //         message: item.content,
          //         type: "warning",
          //         duration: 0,
          //         onClose: () => {
          //           handleInfo({ infoId: item.id }).then((res) => {
          //             if (res.data && res.data.success) {
          //               ElMessage.success(res.data.msg);
          //             } else {
          //               ElMessage.error(res.data.msg);
          //             }
          //           });
          //         },
          //       });
          //     } else if (item.type === 5) {
          //       ElNotification({
          //         title: "教务未回访",
          //         message: item.content,
          //         type: "warning",
          //         duration: 0,
          //         onClose: () => {
          //           handleInfo({ infoId: item.id }).then((res) => {
          //             if (res.data && res.data.success) {
          //               ElMessage.success(res.data.msg);
          //             } else {
          //               ElMessage.error(res.data.msg);
          //             }
          //           });
          //         },
          //       });
          //     } else {
          //       ElNotification({
          //         title: "顾问未回访",
          //         message: item.content,
          //         type: "warning",
          //         duration: 0,
          //         onClose: () => {
          //           handleInfo({ infoId: item.id }).then((res) => {
          //             if (res.data && res.data.success) {
          //               ElMessage.success(res.data.msg);
          //             } else {
          //               ElMessage.error(res.data.msg);
          //             }
          //           });
          //         },
          //       });
          //     }
          //   }, 1000);
          // });
        } else {
          data.noticeArr = [];
        }
      });
    });
    const router = useRouter();
    let data = reactive({
      noticeArr: [
        {
          title: "订单未匹配",
          message: "你有订单仍未匹配成功，请及时处理。",
          type: "warning",
        },
        {
          title: "五星好评",
          message: "你的学生 小高 给研究生打出5星好评。请及时收集课程好评。",
          type: "success",
        },
        {
          title: "未上课",
          message: "你的学生 小高 已经1周未上课，请及时进行干预。",
          type: "error",
        },
      ],
      // 表格数据
      tableData: [],
      date: [], // 时间范围
      formatTime: ["", ""], // 格式化后的时间范围
      statusNum: {},
      tableLoading: false,
      titleOne: '',
      titleTwo: '',
      titleThree: '',
      titleFour: '',
      titleFive: '',
      titleSix: '',
    });
    let search: Function = () => {
      console.log(data.formatTime);
      getJWList(data.formatTime[0], data.formatTime[1]);
    };
    let search2: Function = () => {
      data.formatTime = [];
      data.date.map((item) => {
        let d = new Date(item);
        let time =
          d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        data.formatTime.push(time);
      });
      console.log(data.formatTime);
    };
    let disabledDate = (time: Date) => {
      return time.getTime() > Date.now();
    };
    const goOrder = () => {
      router.push({ path: "/order" });
    };
    const getJWList = (startDay: string, endDay: string) => {
      data.tableLoading = true;
      let params = { startDay, endDay };
      getJWHandleNum(params).then((res) => {
        if (res.data && res.data.success) {
          data.tableData = res.data.data;
          data.tableLoading = false;
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    return {
      ...toRefs(data),
      disabledDate,
      search,
      search2,
      goOrder,
    };
  },
};
